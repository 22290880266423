import axios from "axios";

const $host = axios.create({
    baseURL:'https://wet-love.com:8443/'
})

export {
    $host,
}

